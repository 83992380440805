<script lang="ts" setup>
import type { CoinWithAmount } from '@base/types'

const props = defineProps<{
  forceNarrow?: boolean // true = force flex-col
  error?: string
}>()
const swapStore = useSwapStore()
const { conversionRateString, errors, widgetSwapValue, widgetSwapBinds } = storeToRefs(swapStore)
const { calculateSwapAmounts } = swapStore
const { nextFetchIn } = storeToRefs(useCoins())
const { fetchCoins } = useCoins()

const value = widgetSwapValue
const { cloned } = useCloned(value)

function swapValues () {
  const oldFrom = value.value!.swapFrom
  const oldTo = value.value!.swapTo
  if (value.value!.calculationDirection === 'from') {
    value.value!.swapFrom = oldTo
    value.value!.calculationDirection = 'to'
    value.value!.swapTo = oldFrom
  }
  else {
    value.value!.swapTo = oldFrom
    value.value!.calculationDirection = 'from'
    value.value!.swapFrom = oldTo
  }
  calculateSwapAmounts()
}

function changeFrom (from: CoinWithAmount) {
  value.value!.swapFrom = from
  if (from.amount !== cloned.value!.swapTo?.amount)
    value.value!.calculationDirection = 'from'
  calculateSwapAmounts()
}

function changeTo (to: CoinWithAmount) {
  value.value!.swapTo = to
  if (to.amount !== cloned.value!.swapTo?.amount)
    value.value!.calculationDirection = 'to'
  calculateSwapAmounts()
}

onMounted (() => fetchCoins())
</script>

<template>
  <div>
    <div v-if="value" flex flex-col :class="!props.forceNarrow ? 'lg:flex-row' : ''">
      <div w-full>
        <p paragraph-sm font-500>
          Kolik chcete odeslat?
        </p>
        <SwapInput v-if="value.swapFrom" :model-value="value.swapFrom" :show-only="value.swapFrom.type === 'FIAT' ? 'fiat' : 'tokens'" name="swapFrom" :error="errors['swap.swapFrom']" @update:model-value="changeFrom" />
      </div>
      <div place-self-end relative top-20px lg:top-6px left--16px lg:left-0 lg:place-self-center>
        <FButton
          mx-8px
          name="swap-swap"
          animate-icon="spinCcw" variant="text" size="lg" prefix-icon="i-fubex-swap"
          @click="swapValues"
        >
          Prohodit
        </FButton>
      </div>
      <div w-full>
        <p paragraph-sm font-500>
          Kolik dostanete?
        </p>
        <SwapInput v-if="value.swapTo" :model-value="value.swapTo" :show-only="value.swapTo.type === 'CRYPTO' ? 'tokens' : 'fiat'" name="swapTo" :error="errors['swap.swapTo']" @update:model-value="changeTo" />
        <p paragraph-xs mt-8px>
          {{ conversionRateString }} ({{ nextFetchIn }} s)
        </p>
      </div>
      <div v-if="widgetSwapBinds.error" text-error-default paragraph-md mt-8px>
        {{ widgetSwapBinds.error }}
      </div>
    </div>
  </div>
</template>
