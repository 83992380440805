<script lang="ts" setup>
import type { CoinWithAmount } from '@base/types'

const props = defineProps<{
  name: string
  modelValue: CoinWithAmount
  showOnly?: 'fiat' | 'tokens'
  error?: string
}>()

const emit = defineEmits(['update:modelValue'])
const modelValue = useVModel(props, 'modelValue', emit)
const { coins, tokens, fiatCoins } = storeToRefs(useCoins())

const showCoins = computed(() => {
  if (!props.showOnly)
    return coins.value
  else if (props.showOnly === 'fiat')
    return fiatCoins.value
  else
    return tokens.value.filter(token => token.chainCharges.length > 0)
})

// interface Options {
//   preProcess: (val: string) => string
//   postProcess: (val: string) => string
// }

// // const fiatMaskaOptions: Options = {
// //   preProcess: (val) => {
// //     val = val.replace(/\s+/g, '')// only remove blanks
// //     return val
// //   },
// //   postProcess: (val) => {
// //     if (!val)
// //       return ''

// //     val = val.replace(/\s+/g, '').replace(/,/g, '.')

// //     const decimalLength = 3 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

// //     const processed = Intl.NumberFormat('cs-CZ', {
// //       style: 'decimal',
// //       useGrouping: true,
// //       minimumFractionDigits: 2,
// //       maximumFractionDigits: 2,
// //     }).format(Number(val))
// //       .slice(0, decimalLength ? -decimalLength : undefined)// removes decimal part if not needed

// //     return processed
// //   },
// // }

// // const cryptoMaskaOptions: Options = {
// //   preProcess: (val) => {
// //     val = val.replace(/\s+/g, '')// only remove blanks
// //     return val
// //   },
// //   postProcess: (val) => {
// //     if (!val)
// //       return ''

// //     val = val.replace(/\s+/g, '').replace(/,/g, '.')

// //     const decimalLength = 9 - (val.includes('.') ? val.length - val.indexOf('.') : 0)

// //     const processed = Intl.NumberFormat('cs-CZ', {
// //       style: 'decimal',
// //       useGrouping: false,
// //       minimumFractionDigits: 8,
// //       maximumFractionDigits: 8,
// //     }).format(Number(val))
// //       .slice(0, decimalLength ? -decimalLength : undefined)// removes decimal part if not needed

// //     return processed
// //   },
// // }

function amountChanged (event: Event) {
  if (
    !(
      [',', '.'].includes((event as InputEvent).data!)// , or . was the last character
      || (/,|\./.test((event.target as HTMLInputElement).value) && (event as InputEvent).data === '0') // or input contains , or . and 0 was the last character
    )
  )
    modelValue.value = { ...modelValue.value, amount: +(event.target as HTMLInputElement).value }
}
</script>

<template>
  <div>
    <div w-full relative mt-16px>
      <!-- <input
        v-if="modelValue.type === 'FIAT'"
        v-maska:[fiatMaskaOptions]
        data-maska="0,99"
        data-maska-tokens="0:\d:multiple|9:\d:optional"
        :value="modelValue.amount" type="text" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      >
      <input
        v-else
        v-maska:[cryptoMaskaOptions]
        data-maska="0,99999999"
        data-maska-tokens="0:\d:multiple|9:\d:optional"
        :value="modelValue.amount" type="text" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      > -->
      <input
        v-if="modelValue.type === 'FIAT'"
        :value="modelValue.amount" type="number" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      >
      <input
        v-else
        :value="modelValue.amount" type="number" placeholder="0" w-full
        pl-24px
        pr-130px py-16px fubex-rounded-lg border-1
        border-neutral-200 focus:fubex-focus-ring
        duration-200
        dark="bg-neutral-200 border-neutral-600"
        @input="amountChanged"
      >
      <div
        absolute right-0 top-0 w-130px h-full text-shades-0
      >
        <HeadlessListbox v-model="modelValue">
          <HeadlessListboxButton
            flex justify-center items-center gap-12px
            bg-neutral-900 hover:bg-neutral-600 focus:bg-neutral-600
            h-full w-full focus:fubex-focus-ring fubex-rounded-lg rounded-l-0
            dark:bg-neutral-50 dark:hover:bg-neutral-100 dark:focus:bg-neutral-100 dark:text-neutral-900
            border-1 border-l-0
            duration-200
          >
            <UnoIcon i-fubex-chevron-down h-16px w-16px />
            <img v-if="modelValue.code" :src="`/img/coins/${modelValue.code}.svg`" :alt="modelValue.name" w-24px>
            {{ modelValue.code?.toUpperCase() }}
          </HeadlessListboxButton>
          <HeadlessListboxOptions
            w-full bg-shades-0 z-24 text-neutral-900 mt-8px fubex-rounded-lg fubex-shadow-lg py-16px absolute outline-neutral-900
            dark:bg-neutral-50
            :class="{
              'h-210px overflow-y-scroll': showCoins.length >= 5,
            }"
          >
            <HeadlessListboxOption
              v-for="c in showCoins"
              :key="c.code"
              v-slot="{ active }"
              :value="{ ...c, amount: modelValue.amount }"
            >
              <div
                flex justify-stretch items-center gap-12px pl-32px py-8px
                h-full w-full cursor-pointer hover:bg-neutral-100
                :class="{
                  'bg-neutral-100': active,
                }"
              >
                <img :src="`/img/coins/${c.code}.svg`" :alt="c.name" w-18px>
                {{ c.code.toUpperCase() }}
              </div>
            </HeadlessListboxOption>
          </HeadlessListboxOptions>
        </HeadlessListbox>
      </div>
    </div>
    <div v-if="error" text-error-default paragraph-md mt-8px>
      {{ error }}
    </div>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
